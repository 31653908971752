const ACTIONS = {
  // user
  USER_LOADING: "USER_LOADING",
  ADD_USER: "ADD_USER",
  SET_CURRENT_USER: "SET_CURRENT_USER",
  UPDATE_USER: "UPDATE_USER",
  DESTROY_USER: "DESTROY_USER",
  // availability
  AVAILABILITY_LOADING: "AVAILABILITY_LOADING",
  ADD_AVAILABILITY: "ADD_AVAILABILITY",
  UPDATE_AVAILABILITY: "UPDATE_AVAILABILITY",
  DESTROY_AVAILABILITY: "DESTROY_AVAILABILITY",
  // friendship
  FRIENDSHIP_LOADING: "FRIENDSHIP_LOADING",
  ADD_FRIENDSHIP: "ADD_FRIENDSHIP",
  UPDATE_FRIENDSHIP: "UPDATE_FRIENDSHIP",
  DESTROY_FRIENDSHIP: "DESTROY_FRIENDSHIP",
  // hangtime
  HANGTIME_LOADING: "HANGTIME_LOADING",
  ADD_HANGTIME: "ADD_HANGTIME",
  UPDATE_HANGTIME: "UPDATE_HANGTIME",
  DESTROY_HANGTIME: "DESTROY_HANGTIME",
};

export default ACTIONS;
